import { Player } from "./Player";
import { Albums } from "./Albums";
import { Grid } from "@material-ui/core";
import Style, { Header } from "./GridContainer.style";
import { PlayList } from "./PlayList";

const GridContainer = () => {
  return (
    <>
      <Grid container spacing={0} item={true}>
        <Header xs={12}>
          <img src="/logo512.png" alt="CDW Audio" />
          <Player />
          <PlayList />
        </Header>
        <Style.AlbumsContainer xs={9} sm={6} item={true}>
          <Albums />
        </Style.AlbumsContainer>
      </Grid>
    </>
  );
};

export default GridContainer;
