import styled from "styled-components";
import AudioPlayer from "react-h5-audio-player";

export const CustomAudioPlayer = styled(AudioPlayer)`
  text-align: left;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 50%);
  padding-left: 80px;

  .rhap_additional-controls {
    flex: 0.8 0 0;
  }
  .rhap_main-controls {
    flex: 1.4 0 0;
  }
  .rhap_volume-controls {
    flex: 0.8 0 0;
  }
`;
