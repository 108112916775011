import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MusicType, PlayListType } from "../../types";

export type PlayListState = Readonly<{
  isPlayed: boolean;
  title: string;
  musicList: MusicType[];
  currentPlayed: MusicType | null;
  reset: boolean;
}>;

export const initialState: PlayListState = {
  isPlayed: false,
  title: "Redux Title",
  musicList: [],
  currentPlayed: null,
  reset: false,
};

const playListSlice = createSlice({
  name: "PlayList",
  initialState,
  reducers: {
    playListSet: (state, action: PayloadAction<PlayListType>) => {
      const { isPlayed } = action.payload;

      state.isPlayed = isPlayed;
    },
    playListSetName: (state, action: PayloadAction<PlayListType>) => {
      console.log(state);
      console.log(action);
    },
    playListReset: (state) => {
      state.musicList.splice(0, state.musicList.length);
      state.reset = true;
    },
    playListAddMusic: (state, action: PayloadAction<MusicType>) => {
      const newMusic = action.payload;
      if (!state.musicList.includes(newMusic)) {
        state.musicList.push(newMusic);
      }
      state.reset = false;
    },
    playListSubMusic: (state, action: PayloadAction<MusicType>) => {
      const removeMusic = action.payload;
      /*     const removeMusic: MusicType = {
        url: url,
        name: name,
      };*/
      let removeIndex = state.musicList
        .map((item) => {
          return item.url;
        })
        .indexOf(removeMusic.url);

      state.musicList.splice(removeIndex, 1);
      state.reset = false;
    },
    activePlayList: (state, action: PayloadAction<boolean>) => {
      state.isPlayed = action.payload;
    },
    setMusicPlayed: (state, action: PayloadAction<MusicType | null>) => {
      state.currentPlayed = action.payload;
    },
  },
});

export const {
  playListSet,
  playListAddMusic,
  playListSubMusic,
  playListReset,
  activePlayList,
  setMusicPlayed,
} = playListSlice.actions;
export default playListSlice.reducer;
