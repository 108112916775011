import React, { useState, useEffect } from "react";
import { AlbumsType, AlbumType } from "../types";
import { AlbumDetails } from "./AlbumDetails";

export const Albums = (): JSX.Element => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [albums, setAlbums] = useState<AlbumsType>([]);

  useEffect(() => {
    fetch("https://mp3.lohlwee.org")
      .then((result) => result.json())
      .then((result) => {
        setIsLoaded(true);
        setAlbums(result.albums);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  const [albumOpen, setAlbumOpen] = useState<string | false>("");
  const onChangeAlbum = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setAlbumOpen(newExpanded ? panel : false);
  };

  if (error !== null) {
    return <div>Erreur : {error}</div>;
  } else if (!isLoaded) {
    return <div>Chargement...</div>;
  } else {
    return (
      <>
        {albums !== undefined &&
          albums.map((album: AlbumType) => (
            <AlbumDetails
              key={album.name}
              album={album}
              expandedAlbum={albumOpen}
              onChangeAlbum={onChangeAlbum}
            />
          ))}
      </>
    );
  }
};
