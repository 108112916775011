import styled from "styled-components";
import { Card, Button } from "@material-ui/core";

export const PlayListDisplay = styled(Card)`
  margin: 15px auto;
  height: 80px;
  width: 96%;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 50%);
  white-space: normal;
  overflow: auto;
  background-image: url(/playlist.png);
  background-position: center;
  background-repeat: no-repeat;
  beackground-size: contain;
  text-align: center;

  .MuiButton-label {
    /* max-width: 60px;
    white-space: nowrap;
    text-overflow: ellipsis;*/
  }
`;

export const PlayListMusicDisplay = styled(Button)`
  white-space: nowrap;
  margin: 3px;
`;

export const OptionsPlaylistLeft = styled.div`
  position: absolute;
  left: 2px;
  top: 165px;
`;
export const OptionsPlaylistRight = styled.div`
  position: absolute;
  right: 2px;
  top: 165px;
`;
