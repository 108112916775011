import "./App.css";
import GridContainer from "./components/GridContainer";
import { Provider } from "react-redux";
import { getReduxStore } from "./redux/store";

const store = getReduxStore();

const App = (): JSX.Element => {
  return (
    <Provider store={store}>
      <div className="App">
        <GridContainer />
      </div>
    </Provider>
  );
};

export default App;
