import { getCurrentPlayed } from "../redux/PlayList";
import { useSelector } from "react-redux";
import { CustomTitlePlay } from "./TitlePlay.style";

export const TitlePlay = () => {
  const currentMusic = useSelector(getCurrentPlayed);
  const musicTitle = currentMusic !== null ? currentMusic.name : "...";

  return <CustomTitlePlay>{musicTitle}</CustomTitlePlay>;
};
