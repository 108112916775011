import { useMemo } from "react";
import { applyMiddleware, createStore, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reducer, { initialState as appInitialState } from "./reducers";
import { RootState } from "./types";

type AppStore = Store<RootState>;
let store: AppStore | undefined = undefined;

const initStore = (preloadedState = appInitialState): AppStore => {
  /* if (process.env.NODE_ENV === 'production') {
    return createStore(reducer, preloadedState, applyMiddleware());
  }*/

  return createStore(
    reducer,
    preloadedState,
    composeWithDevTools(applyMiddleware())
  );
};

export const getReduxStore = (preloadedState?: RootState): AppStore => {
  let newStore = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState !== undefined && store !== undefined) {
    newStore = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") {
    return newStore;
  }

  // Create the store once in the client
  if (store === undefined) {
    store = newStore;
  }

  return newStore;
};

export const useStore = (initialState: RootState): AppStore => {
  return useMemo(() => getReduxStore(initialState), [initialState]);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { store };
