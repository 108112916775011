import { Grid } from "@material-ui/core";
import styled from "styled-components";

const PlayListContainer = styled(Grid)`
  position: fixed;
  margin: 100px auto 30px auto;
`;

const AlbumsContainer = styled(Grid)`
  margin: 200px auto 30px auto;
`;

export const Header = styled(Grid)`
  position: fixed;
  width: 100%;
  z-index: 10;
  text-align: left;

  img {
    position: absolute;
    width: 60px;
    left: 5px;
    top: 5px;
  }
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default { AlbumsContainer, PlayListContainer, Header };
