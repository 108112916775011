import { combineReducers } from "redux";
import {
  reducer as playList,
  initialState as playListInitialState,
} from "./PlayList";

import { RootState } from "./types";

export const initialState: RootState = {
  playList: playListInitialState,
};

const rootReducers = combineReducers({
  playList,
});

export default rootReducers;
