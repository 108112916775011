import {
  IconButton,
  ListItemSecondaryAction,
  ListItem,
  FormControlLabel,
} from "@material-ui/core";
import { MusicNote } from "@material-ui/icons";
import Switch from "@material-ui/core/Switch";
import { MusicType } from "../types";
import { useDispatch, useSelector } from "react-redux";
import {
  activePlayList,
  getPlayList,
  playListAddMusic,
  playListSubMusic,
  setMusicPlayed,
} from "../redux/PlayList";
import md5 from "md5";
import { useEffect, useState } from "react";

type Props = {
  music: MusicType;
};

export const MusicDisplay = ({ music }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const playList = useSelector(getPlayList);
  const [switchChecked, setSwitchChecked] = useState<boolean>(false);

  const setMusicPlay = (music: MusicType) => {
    dispatch(activePlayList(false));
    dispatch(setMusicPlayed(music));
  };

  const musicToPlayList = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    if (checked) {
      dispatch(playListAddMusic(music));
    } else {
      dispatch(playListSubMusic(music));
    }
  };

  useEffect(() => {
    if (playList.includes(music)) {
      setSwitchChecked(true);
    } else {
      setSwitchChecked(false);
    }
  }, [switchChecked, music, playList]);

  return (
    <ListItem>
      <FormControlLabel
        value={music.name}
        control={
          <Switch
            color="primary"
            id={`music_${md5(music.url)}`}
            checked={switchChecked}
          />
        }
        label={music.name}
        labelPlacement="end"
        onChange={(event) =>
          musicToPlayList(event as React.ChangeEvent<HTMLInputElement>)
        }
        checked={switchChecked}
      />
      <ListItemSecondaryAction>
        <IconButton
          children={<MusicNote />}
          color="primary"
          onClick={() => setMusicPlay(music)}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};
