import { useSelector } from "react-redux";
import { getCurrentPlayed, getPlayList } from "../redux/PlayList";
import { Grid, Fab } from "@material-ui/core";
import { Delete, MusicNote } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import {
  activePlayList,
  setMusicPlayed,
  playListReset,
} from "../redux/PlayList";
import {
  PlayListMusicDisplay,
  PlayListDisplay,
  OptionsPlaylistLeft,
  OptionsPlaylistRight,
} from "./PlayList.style";
import { MusicType } from "../types";

export const PlayList = () => {
  const dispatch = useDispatch();
  const playList = useSelector(getPlayList);
  const currentMusic = useSelector(getCurrentPlayed);

  const resetPlayList = () => {
    dispatch(activePlayList(false));
    dispatch(playListReset());
  };

  const activePlayerPlayList = () => {
    dispatch(activePlayList(true));
    const music = playList[0];
    dispatch(setMusicPlayed(null));
    dispatch(setMusicPlayed(music));
  };

  const setPlayListMusic = (music: MusicType) => {
    dispatch(activePlayList(true));
    dispatch(setMusicPlayed(null));
    dispatch(setMusicPlayed(music));
  };

  return (
    <PlayListDisplay>
      <Grid xs={12}>
        {playList.map((music) =>
          music === currentMusic ? (
            <PlayListMusicDisplay
              key={music.name}
              variant="contained"
              color="secondary"
              size="small"
            >
              <b>{music.name}</b>
            </PlayListMusicDisplay>
          ) : (
            <PlayListMusicDisplay
              key={music.name}
              variant="contained"
              color="primary"
              size="small"
            >
              <span onClick={() => setPlayListMusic(music)}>{music.name}</span>
            </PlayListMusicDisplay>
          )
        )}
        {playList.length > 0 && (
          <>
            <OptionsPlaylistLeft>
              <Fab children={<Delete />} onClick={resetPlayList} size="small" />
            </OptionsPlaylistLeft>
            <OptionsPlaylistRight>
              <Fab
                children={<MusicNote />}
                onClick={activePlayerPlayList}
                size="small"
              />
            </OptionsPlaylistRight>
          </>
        )}
      </Grid>
    </PlayListDisplay>
  );
};
