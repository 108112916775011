import styled from "styled-components";
import { Card } from "@material-ui/core";

export const CustomTitlePlay = styled(Card)`
  width: 80px;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 50%);
  font-size: 11px;
  background-color: #000;
  color: #0f0;
  padding: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
