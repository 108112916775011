import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { AlbumType } from "../types";
import { cleanAlbumName } from "../utils";
import { MusicDisplay } from "./MusicDisplay";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ChangeEvent } from "react";
import Style from "./AlbumDetails.style";

type Props = {
  album: AlbumType;
  onChangeAlbum: (
    panel: string
  ) => (event: ChangeEvent<{}>, expanded: boolean) => void;
  expandedAlbum: string | false;
};

export const AlbumDetails = ({
  album,
  onChangeAlbum,
  expandedAlbum,
}: Props): JSX.Element => {
  const contentName = `${album.name}-content`;
  const headerName = `${album.name}-header`;

  return (
    <Accordion
      expanded={expandedAlbum === album.name}
      onChange={onChangeAlbum(album.name)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={contentName}
        id={headerName}
      >
        <Typography>{cleanAlbumName(album.name)}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Style.CustomList dense={true}>
          {album.list.map((music) => (
            <MusicDisplay key={music.url} music={music} />
          ))}
        </Style.CustomList>
      </AccordionDetails>
    </Accordion>
  );
};
