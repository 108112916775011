import "react-h5-audio-player/lib/styles.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getCurrentPlayed,
  getPlayList,
  isPlayListPlayed,
  setMusicPlayed,
} from "../redux/PlayList";
import { MusicType } from "../types";
import { CustomAudioPlayer } from "./Player.style";
import { TitlePlay } from "./TitlePlay";

export const Player = () => {
  const playListPlayed = useSelector(isPlayListPlayed);
  const currentPlayed = useSelector(getCurrentPlayed);
  const playList = useSelector(getPlayList);
  const dispatch = useDispatch();

  const src = currentPlayed !== null ? currentPlayed.url : "";

  const playNext = () => {
    let startFindNext = false;
    let choosen = false;
    playList.map((music) => {
      if (music === currentPlayed) {
        startFindNext = true;
      } else if (startFindNext && !choosen) {
        dispatch(setMusicPlayed(music));
        choosen = true;
      }
      return true;
    });
  };

  const playPrevious = () => {
    let previous: MusicType | null = null;
    let choosen = false;
    playList.map((music) => {
      if (music === currentPlayed && !choosen && previous !== null) {
        dispatch(setMusicPlayed(previous));
        choosen = true;
      }
      previous = music;

      return true;
    });
  };

  const thisIsTheEnd = () => {
    if (!playListPlayed) {
      dispatch(setMusicPlayed(null));
    } else {
      playNext();
    }
  };

  return (
    <CustomAudioPlayer
      autoPlay
      src={src}
      onPlay={(e) => console.log("onPlay")}
      onEnded={() => {
        thisIsTheEnd();
      }}
      onClickNext={() => {
        playNext();
      }}
      onClickPrevious={() => {
        playPrevious();
      }}
      showSkipControls={playListPlayed}
      showJumpControls={false}
      customAdditionalControls={[<TitlePlay />]}

      // other props here
    />
  );
};
