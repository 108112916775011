import { MusicType } from "../../types";
import { RootState } from "../types";

export const resetPending = (state: RootState): boolean => {
  return state.playList.reset;
};

export const isPlayListPlayed = (state: RootState): boolean => {
  return state.playList.isPlayed;
};

export const getPlayListTitle = (state: RootState): string => {
  return state.playList.title;
};

export const getPlayList = (state: RootState): MusicType[] => {
  return state.playList.musicList;
};

export const getCurrentPlayed = (state: RootState): MusicType | null => {
  return state.playList.currentPlayed;
};
